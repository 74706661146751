export const propertySubscriptionTypes = [
  { name: 'Free Trial', planKey: 'free-trial' },
  { name: 'One time purchase', planKey: '1-request' },
  { name: 'Subscription 10/mo', planKey: '10-a-month' },
  { name: 'Subscription 50/mo', planKey: '50-a-month' },
  { name: 'Subscription 100/mo', planKey: '100-a-month' },
  { name: 'Reporter', planKey: 'reporter' },
  { name: 'Custom', planKey: 'custom' },
]

export const fixedAmountPropertySubscriptionTypes =
  propertySubscriptionTypes.filter(({ planKey }) => planKey !== 'custom')
