const globalModalService = [
  'globalNotificationsModel',
  '$uibModal',
  'urlService',
  'referralAgreementModalService',
  'modalScreenService',
  function (
    globalNotificationsModel,
    $uibModal,
    urlService,
    referralAgreementModalService,
    modalScreenService
  ) {
    const modalKeyHandlers = {
      WIN_RATE: ({ recentProjectCount, winNumber, modalSeverity }) => {
        $uibModal.open({
          templateUrl: urlService.templateUrl('contractor/home/win_rate_modal'),
          controller: 'WinRateModalCtrl',
          controllerAs: '$ctrl',
          windowClass: 'v3-modal-simple large win-rate-modal',
          resolve: {
            inputData: () => ({
              recentProjectsReceived: recentProjectCount,
              projectsWon: winNumber,
              modalSeverity,
            }),
          },
        })
      },
      UNSUBSCRIBE_CONFIRMATION: ({
        communicableId,
        communicableType,
        communicationType,
        subscriptionType,
        subscriptionText,
      }) => {
        modalScreenService.initDetachedComponent({
          component: 'unsubscribeConfirmationModal',
          windowClass: 'v3-modal-simple extra-thin',
          inputData: {
            communicableId: communicableId,
            communicableType: communicableType,
            communicationType: communicationType,
            subscriptionType: subscriptionType,
            subscriptionText: subscriptionText,
          },
        })
      },
      REFERRAL_AGREEMENT: (hasOnlyExpiredSignedReferralAgreements) => {
        const pathname = window.location.pathname
        referralAgreementModalService.launchModal({
          source:
            pathname.indexOf('manage') > -1
              ? 'dashboard page load popup'
              : 'lead page load popup',
          hasSignedReferralAgreement: false,
          hasOnlyExpiredSignedReferralAgreements,
        })
      },
    }

    return {
      checkActiveGlobalModal(shouldCacheResponse) {
        if (UserData.user_signed_in() && !UserData.is_admin()) {
          globalNotificationsModel
            .getModalToShow(shouldCacheResponse)
            .then((modalData) => {
              if (modalData) {
                const { alertKey, alertData } = modalData
                if (alertKey) modalKeyHandlers[alertKey](alertData)
              }
            })
            .catch((e) => {
              console.error(e)
            })
        }
      },
    }
  },
]

export default globalModalService
